import React from 'react';
import algoDB from '../assets/algoDB.png';
import spats from '../assets/spats.png';
import taskier from '../assets/taskier.png';
import mergesort from '../assets/mergesort.png';
import fractaltree from '../assets/fractaltree.png';
import codecracker from '../assets/codecracker.png';

const Work = () => {
  return (
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>
            Work
          </p>
          <p className='py-6'>// Check out some of my recent work</p>
        </div>

{/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${algoDB})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <p className='text-2xl text-center font-bold mb-1 text-white'>
                algoDB
              </p>
              <p className='text-base text-white text-center mb-1'>The Data Structures and Algorithms Database</p>
              <p className='text-sm text-white text-center mt-2'>React | Ruby on Rails</p>
              <div className='pt-8 text-center'>
                <a href='http://algo-db.hagayhaut.com/' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Live
                  </button>
                </a>
                <a href='https://github.com/HagayHaut/algo-db' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${spats})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <p className='text-2xl text-center font-bold mb-1 text-white'>
                Spats
              </p>
              <p className='text-base text-white text-center mb-1'>Secure App for Tracking User Listening History</p>
              <p className='text-sm text-white text-center mt-2'>React | Ruby on Rails</p>
              <div className='pt-8 text-center'>
                <a href='https://www.loom.com/share/195f7404fd724c3e9d88d3c78704d1b4' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/HagayHaut/spats-frontend' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${mergesort})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <p className='text-2xl text-center font-bold mb-1 text-white'>
                Mergesort Visualizer
              </p>
              <p className='text-base text-white text-center mb-1'>To better understand the Mergesort algorithm, I animated it.</p>
              <p className='text-sm text-white text-center mt-2'>React</p>
              <div className='pt-8 text-center'>
                <a href='https://hagayhaut.github.io/sorting-visualizer/' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Live
                  </button>
                </a>
                <a href='https://github.com/HagayHaut/sorting-visualizer' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${fractaltree})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <p className='text-2xl text-center font-bold mb-1 text-white'>
                Fractal Tree
              </p>
              <p className='text-base text-white text-center mb-1'>Fractal Binary Tree visualizer with user controlled branching angle.</p>
              <p className='text-sm text-white text-center mt-2'>JS | p5.js | CSS</p>
              <div className='pt-8 text-center'>
                <a href='https://hagayhaut.github.io/fractal-tree/' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Live
                  </button>
                </a>
                <a href='https://github.com/HagayHaut/fractal-tree' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${taskier})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <p className='text-2xl text-center font-bold mb-1 text-white'>
                Taskier
              </p>
              <p className='text-base text-white text-center mb-1'>Task managing application with reminders, due dates, and dark mode.</p>
              <p className='text-sm text-white text-center mt-2'>React | Ruby | Sinatra</p>
              <div className='pt-8 text-center'>
                <a href='https://taskier.bradbieselin.com/' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Live
                  </button>
                </a>
                <a href='https://github.com/HagayHaut/phase-3-project-backend'  target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${codecracker})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
            <p className='text-2xl text-center font-bold mb-1 text-white'>
                CodeCracker
              </p>
              <p className='text-base text-white text-center mb-1'>Code guessing game with difficulty settings, auto-guesses, and multiple game boards.</p>
              <p className='text-sm text-white text-center mt-2'>React</p>
              <div className='pt-8 text-center'>
              
                <a href='https://github.com/HagayHaut/code-cracker' target='_blank'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
